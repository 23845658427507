export const allPosts = [

    /********************************* BODY TAGS *********************************

    <figure><img src='/images/xxxxxxxxxxxxxxxxxxxxxxxxxxx.webp' alt='xxxxxxxxxxxxxxxxxxxxxxxxxxx' loading='lazy'/><figcaption>xxxxxxxxxxxxxxxxxxxxxxxxxxx</figcaption></figure>

    <h2>xxxxxxxxxxxxxxxxxxxxxxxxxxx</h2>

    <p>xxxxxxxxxxxxxxxxxxxxxxxxxxx</p>

    <a href='xxxxxxxxxxxxxxxxxxxxxxxxxxx' target='_blank'>xxxxxxxxxxxxxxxxxxxxxxxxxxx</a>

    <div><p>xxxxxxxxxxxxxxxxxxxxxxxxxxx</p><ul><li>xxxxxxxxxxxxxxxxxxxxxxxxxxx</li><li>xxxxxxxxxxxxxxxxxxxxxxxxxxx</li><li>xxxxxxxxxxxxxxxxxxxxxxxxxxx</li></ul></div>

    <div><p>xxxxxxxxxxxxxxxxxxxxxxxxxxx</p><ol><li>xxxxxxxxxxxxxxxxxxxxxxxxxxx</li><li>xxxxxxxxxxxxxxxxxxxxxxxxxxx</li><li>xxxxxxxxxxxxxxxxxxxxxxxxxxx</li></ol></div>

    <iframe class='youtube-video' src='xxxxxxxxxxxxxxxxxxxxxxxxxxx' title='xxxxxxxxxxxxxxxxxxxxxxxxxxx' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share' allowfullscreen></iframe>

    <div class='external-link-button-wrapper'><a href='https://xxxxxxxxxxxxxxxxxxxxxxxxxxx' target='_blank' class='external-link-button'>Visit xxxxxxxxxxxxxxxxxxxxxxxxxxx for more info</a></div>

    ********************************* BODY TAGS *********************************/



    /********************************* TEMPLATE *********************************
    {
        name: "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx",
        slug: "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx",
        shortName: "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx",
        description: "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx",
        body: "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx",
        image: {
            src: "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx.webp",
            alt: "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
        },
        category: "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx",
        tags: [
            "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx",
            "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx",
            "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
        ],
        author: "FunnyGifts.uk",
        published: "xxxx-xx-xx",
        modified: "xxxx-xx-xx"
    },
    ********************************* TEMPLATE *********************************/

    {
        name: "Funny Wanker Gifts",
        slug: "funny-wanker-gifts",
        shortName: "Wanker Gifts",
        description: "Wanker gifts for people whose hand is always down their pants.",
        body: "<p>Masturbating is a natural human activity. There are some people who do it as a hobby, like playing golf, reading a book, or hiking. And like other hobbies, you can buy products and accessories that will enhance the self-stimulating experience.</p><p>If you know someone who does masturbating as a hobby, get them a wanker gift so they can have much more fun with themself.</p>",
        image: {
            src: "wank-diary.webp",
            alt: "Wank Diary"
        },
        category: "",
        tags: [
            "wanker"
        ],
        author: "FunnyGifts.uk",
        published: "2024-03-28",
        modified: ""
    },
    {
        name: "Funny Sweary Gifts",
        slug: "funny-sweary-gifts",
        shortName: "Sweary Gifts",
        description: "Sweary gifts for those who love to curse at every given opportunity.",
        body: "We all love to have a good swear from time to time. But there are those who enjoy it a bit too much, like grannies. When they spend time with others, every other word that comes out of their mouth is a swear word. Swearing is like a drug to them. Every time they say the 'C' word, they get a real high from it and need to say it again. It's a problem that all women will inevitably face when they reach old age.<p>The sweary gifts below will either solve this problem or make it worse. What you choose for them will determine what society will like be in the future. Choose wisely!</p>",
        image: {
            src: "how-to-swear-an-illustrated-guide.webp",
            alt: "How to Swear: An illustrated Guide"
        },
        category: "",
        tags: [
            "swearing"
        ],
        author: "FunnyGifts.uk",
        published: "2024-04-26",
        modified: ""
    },
    {
        name: "Funny Rude Gifts",
        slug: "funny-rude-gifts",
        shortName: "Rude Gifts",
        description: "Funny rude gifts for the idiots in your life.",
        body: "<p>The world is full of idiots, but some of them don't realise they are and blissfully continue to be one to the annoyance of others. So how do you put an end to their idiocy? Give them one of these funny rude gifts in the hope that they'll acknowledge their negative trait and change to become a more tolerable human being.</p>",
        image: {
            src: "cunt-mug.webp",
            alt: "CUNT Mug"
        },
        category: "",
        tags: [
            "rude"
        ],
        author: "FunnyGifts.uk",
        published: "2024-03-25",
        modified: ""
    },
    {
        name: "Funny Weird Gifts",
        slug: "funny-weird-gifts",
        shortName: "Weird Gifts",
        description: "Funny weird gifts for those who may not be all there.",
        body: "<p>Do you get insulted by the lack of enthusiasm on people's faces when they unwrap your presents? If so, you are giving them boring gifts.</p><p>Have a look at some of the funny weird gifts below and buy one for someone's birthday or as a Christmas present. We guarantee they won't look bored when they they receive it. If anything, they'll look really confused as to why you bought them it, and will begin to think you're a weirdo.</p>",
        image: {
            src: "finger-hands.webp",
            alt: "Finger Hands"
        },
        category: "",
        tags: [
            "weird",
        ],
        author: "FunnyGifts.uk",
        published: "2024-02-08",
        modified: ""
    },
    {
        name: "Funny Middle Finger Gifts",
        slug: "funny-middle-finger-gifts",
        shortName: "Middle Finger Gifts",
        description: "Funny middle finger gifts for those who love swearing without opening their mouth.",
        body: "<p>Swearing at randoms, little old ladies, and children can be hard work, especially if you're holding up your arm and middle finger for hours on end. After a long day of flipping the bird at our fellow human beings, it can feel like we've done a long arm day at the gym.</p><p>If you get immense pleasure from showing others your middle finger but don't have the physical strength to do it all day, get one of these funny gifts so you can swear at them without lifting a finger.</p>",
        image: {
            src: "middle-finger-candle.webp",
            alt: "Middle Finger Candle"
        },
        category: "",
        tags: [
            "middle finger"
        ],
        author: "FunnyGifts.uk",
        published: "2024-02-08",
        modified: ""
    },
    {
        name: "Funny Poop Gifts",
        slug: "funny-poop-gifts",
        shortName: "Poop Gifts",
        description: "Funny poop-themed gifts for people who really enjoy emptying their bowels.",
        body: "<p>If you enjoy dropping off your dirty passengers on the toilet more than spending time with your family, that's completely understandable. Pooing is fun and relaxing. We love looking down to see the masterpiece we have just created. Sometimes we take a photo of it so we can reminisce the great time we had earlier in the day on the toilet.</p><p>The unfortunate thing is that most of us generally don't poo more than once a day. There are some people who can drop a load several times a day. Congratulations to you if you're one of those people. We envy you!</p><p>For us mere mortals, here are some funny poop-themed gifts that will keep you entertained until your next visit to the toilet.</p>",
        image: {
            src: "baby-poop-stress-toy.webp",
            alt: "Baby Poop Stress Toy"
        },
        category: "",
        tags: [
            "poop"
        ],
        author: "FunnyGifts.uk",
        published: "2024-02-08",
        modified: ""
    },
    {
        name: "Funny Fart Gifts",
        slug: "funny-fart-gifts",
        shortName: "Fart Gifts",
        description: "Funny fart-themed gifts for those who love producing and smelling their own as well as others.",
        body: "<p>We all love farting. We love smelling them even more, looking around to make sure no one saw or heard us enjoying our own produce.</p><p>But what do we do when our bodies run out of gas? We follow randoms on the street like a chicken following its human owner around a farm in the hopes that they release a toxic one in order to get our hit. Once we regain conciousness after passing out from that high, we find another person who looks like they've had a big lunch and do it all over again until it gets dark.</p><p>However, not all of us are lucky enough to have time to follow people and smell their farts. One of our life goals at FunnyGifts.uk is to find all the funny fart-themed gifts that are worth buying so flatulence lovers can enjoy the experience in some form when our bodies fail to produce that intoxicating odour.</p>",
        image: {
            src: "farts-a-spotters-guide.webp",
            alt: "Farts: A Spotter's Guide"
        },
        category: "",
        tags: [
            "farting"
        ],
        author: "FunnyGifts.uk",
        published: "2024-02-08",
        modified: ""
    },
    {
        name: "Funny Penis Gifts",
        slug: "funny-penis-gifts",
        shortName: "Penis Gifts",
        description: "Funny penis-themed gifts for people obssessed with the male appendage.",
        body: "<p>Do you know someone who is obssessed with willies? Do they walk around town staring at every crotch they encounter and drool with excitement?</p><p>If they aren't polite enough to look at men's faces when they talk to them, it's time they were taught some manners and given some products that will help them keep their eyes to themselves.</p><p>Ranging from books to toys and games, these funny penis-themed gifts will help wean them off staring at random's crotches.</p>",
        image: {
            src: "pierre-the-penis-body-pillow.webp",
            alt: "Pierre the Penis Body Pillow"
        },
        category: "",
        tags: [
            "penis"
        ],
        author: "FunnyGifts.uk",
        published: "2024-02-08",
        modified: ""
    },
];